import React from "react"
import styled from "styled-components"

import { useUpdatePayrollStatus } from "../../auth/hooks"
import { Chip, Button, Modal } from "../../components"

const Container = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;

  .title {
    font-size: 20px;
    line-height: 30px;
  }

  .chip-container {
    display: flex;
  }

  p {
    font-size: 12px;
    margin: 0;
  }
`

const PayrollTable = styled.div`
  grid-column: span 2;
  display: grid;
  grid-template-columns: repeat(3, minmax(min-content, 1fr)) max-content;
  grid-column-gap: 5px;
  align-items: center;

  p {
    padding: 5px;
  }

  .table-heading {
    line-height: 15px;
    font-weight: 500;
  }

  .heading-placeholder {
    display: none;
  }

  .inline-heading {
    grid-column: 1 / 6;
    border-bottom: 1px solid ${({ theme }) => theme.palette.mineShaft};
    margin-bottom: 5px;
  }

  .description {
    grid-column: 1;
  }

  hr {
    &.primary {
      grid-column: 1 / 6;
      border-bottom: 1px solid ${({ theme }) => theme.palette.mineShaft};
      margin: 5px 0px;
    }

    &.secondary {
      grid-column: 1 / 6;
      border-bottom: 1px solid ${({ theme }) => theme.palette.porcelain};
      margin-bottom: 0px;
    }
  }

  .total-column {
    text-align: right;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-template-columns: minmax(min-content, max-content) repeat(3, 1fr) max-content;
    grid-column-gap: 10px;

    .heading-placeholder {
      display: inline-block;
    }

    .inline-heading {
      grid-column: 1;
      border-bottom: none;
      margin-bottom: 0px;
    }

    .description {
      grid-column: 2;
    }
  }
`

const TotalHeader = styled.h5`
  grid-column: -4 / -2;
  font-size: 13px;
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: 500;

  &.grand-total {
    font-weight: 700;
    margin-top: 20px;
  }
`

const TotalValue = styled.h6`
  grid-column: -2 / -1;
  font-size: 16px;
  font-weight: 700;
  text-align: right;
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: 500;

  &.grand-total {
    font-size: 26px;
    margin-top: 20px;
  }
`
const StyledButton = styled(Button)`
  grid-column: 2 / 5;
  margin-top: 20px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-column: 5 / 6;
  }
`

interface Props {
  open: boolean
  onClose: () => void
  payroll: Reseller.Payroll
}

const renderPayrolLineItems = (payroll: Reseller.Payroll) => {
  return payroll.lineItems?.map((item) => {
    // If item.quantity does not exist then it's assumed that we're looking at a percentage amount
    const lineItemTotal =
      item.quantity && item.itemAmount
        ? (item.quantity * item.itemAmount).toFixed(2)
        : (item.totalAmount - item.totalPrincipalCut).toFixed(2)

    return (
      <React.Fragment key={`payroll-line-item-${item.id}`}>
        <p
          style={{
            gridRow: `span ${
              payroll.lineItems.length + (payroll.lineItems.length - 1)
            }`,
          }}
          className="table-heading inline-heading"
        >
          {item.adHocCategory
            ? item.adHocCategory
            : `Performance for ${payroll.name}`}
        </p>

        <p className="description">{item.description}</p>
        <p>{item.quantity ? item.quantity : item.percentage + "%"}</p>
        <p>{item.itemAmount ? `R${item.itemAmount}` : "-"}</p>
        <p className="total-column">R{lineItemTotal}</p>
        <hr className="primary" />
      </React.Fragment>
    )
  })
}

const PayrollModal: React.FC<Props> = ({ open, onClose, payroll }: Props) => {
  const updatePayrollStatus = useUpdatePayrollStatus()

  return (
    <Modal open={open} onClose={onClose}>
      <Container>
        <h3 className="title">
          Performance breakdown <br />
          For: {payroll.name}
        </h3>
        <div className="chip-container">
          <Chip
            size="large"
            text={payroll.status ? payroll.status : ""}
            backgroundColor="#dff5fb"
            textColor="#00a9e0"
            borderColor="transparent"
          />
        </div>
        <p>Date: {payroll.createdAt}</p>
        <PayrollTable>
          {/* Table headers */}
          <p className="table-heading">Description</p>
          <span className="table-heading heading-placeholder" />
          <p className="table-heading">Units</p>
          <p className="table-heading">Amount</p>
          <p className="table-heading total-column">Total</p>
          <hr className="primary" />

          {/* Table Body */}
          {renderPayrolLineItems(payroll)}
          <TotalHeader>{payroll.name}&apos;s cut</TotalHeader>
          <TotalValue>R {payroll.totalAssociateCut.toFixed(2)}</TotalValue>
          <hr className="secondary" />

          <TotalHeader>Your Cut</TotalHeader>
          <TotalValue>R {payroll.totalPrincipalCut.toFixed(2)}</TotalValue>
          <hr className="secondary" />

          <TotalHeader className="grand-total">Gross Total</TotalHeader>
          <TotalValue className="grand-total">
            R {payroll.totalAmount.toFixed(2)}
          </TotalValue>
          {payroll.status === "UNPAID" ? (
            <StyledButton
              color="#00a9e0"
              textColor="#fff"
              label="Mark as Paid"
              iconPath="/images/icons/icon-circled-check.svg#base"
              onClick={() => updatePayrollStatus([payroll.id], "PAID")}
            />
          ) : null}
        </PayrollTable>
      </Container>
    </Modal>
  )
}

export default PayrollModal
