import { NetworkStatus } from "@apollo/client"
import { Link } from "gatsby"
import React, { useState, useMemo, useCallback } from "react"
import styled from "styled-components"

import { useNotify } from "../../alert/hooks"
import {
  useGetPayrolls,
  useUser,
  useUpdatePayrollStatus,
  useExportPayrolls,
} from "../../auth/hooks"
import {
  PageHeader,
  PageGuard,
  Filter,
  Table as TableBase,
  Chip,
  ActionDropdown,
  Checkbox,
  PageNotificationBanner,
} from "../../components"
import Seo from "../../components/seo"
import { TableHeading } from "../../components/Table"
import PayrollModal from "../../principal/components/PayrollModal"
import { formatDateToString } from "../../utils/date"

const Table = styled(TableBase)`
  margin: 20px 0;

  .table-row {
    grid-template-areas:
      "checkbox associate action action"
      ". invoiceDate invoiceDate amountOwed";

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      grid-template-areas: "checkbox associate status invoiceDate amountOwed action";
    }
  }
`

const ChipContainer = styled.div`
  display: flex;
  grid-area: invoiceDate;
  justify-self: start;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-area: status;
  }
`

const AssociateContainer = styled.div`
  grid-area: associate;
  overflow: hidden;

  .associate-email {
    font-weight: 500;
    line-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .associate-name {
    font-size: 12px;
    line-height: 22px;
  }
`

const RowTextField = styled.p<{
  gridArea: string
}>`
  grid-area: ${({ gridArea }) => gridArea};
  padding-right: 5px;
  text-align: center;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    text-align: left;
  }
`

const BulkActionCheckbox = styled(Checkbox)`
  grid-area: checkbox;
`

const PayrollStatus = {
  PAID: "PAID",
  UNPAID: "UNPAID",
}

interface FilterOption {
  title: string
  filterRef?: Reseller.PayrollStatusType
  quantity?: number
}

const renderChip = (status: string, index: number) => {
  const borderColor =
    status === PayrollStatus.PAID
      ? "#e1eae8"
      : status === PayrollStatus.UNPAID
      ? "#ffd4c6"
      : ""

  const textColor =
    status === PayrollStatus.PAID
      ? "#00a9e0"
      : status === PayrollStatus.UNPAID
      ? "#fa5d37"
      : ""

  const text =
    status === PayrollStatus.PAID
      ? "Paid"
      : status === PayrollStatus.UNPAID
      ? "Unpaid"
      : ""

  return (
    <ChipContainer key={`chip-container-${index}`}>
      <Chip
        text={text}
        backgroundColor="inherit"
        borderColor={borderColor}
        borderWidth="2px"
        textColor={textColor}
        size="medium"
        fontWeight="500"
      />
    </ChipContainer>
  )
}

const PrincipalPayroll = (): React.ReactElement => {
  const { data, loading, refetch, fetchMore } = useGetPayrolls()
  const [getExportedPayrolls, networkStatus] = useExportPayrolls()
  const exportPayrollsLoading = networkStatus !== NetworkStatus.ready

  const { user } = useUser()
  const notify = useNotify()
  const updatePayrollStatus = useUpdatePayrollStatus()
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(0)
  const [payrollModalOpen, setPayrollModalOpen] = useState(false)
  const [selectedPayrollIndex, setSelectedPayrollIndex] = useState(0)
  const [selectAll, setSelectAll] = useState(false)
  const [selectedPayrolls, setSelectedPayrolls] = useState<string[]>([])

  const payrolls = data?.edges.map((payroll: { node: Reseller.Payroll }) => {
    return {
      id: payroll.node.id,
      associateDetails: {
        name: payroll.node.name,
        email: payroll.node.email,
      },
      status: payroll.node.status,
      createdAt: payroll.node.createdAt,
      canPay: payroll.node.canPay,
      totalPrincipalCut: payroll.node.totalPrincipalCut,
      totalAssociateCut: payroll.node.totalAssociateCut,
      totalVatAmount: payroll.node.totalVatAmount,
      totalAmountExclVat: payroll.node.totalAmountExclVat,
      totalAmount: payroll.node.totalAmount,
      lineItems: payroll.node.lineItems,
    }
  })

  const payrollFilters: FilterOption[] = useMemo(
    () => [
      {
        title: "All Payrolls",
        filterRef: undefined,
        quantity: data?.count?.all,
      },
      {
        title: "Unpaid",
        filterRef: "UNPAID",
        quantity: data?.count?.unpaid,
      },
      {
        title: "Paid",
        filterRef: "PAID",
        quantity: data?.count?.paid,
      },
    ],
    [data]
  )

  const bulkActionsList = useMemo(
    () => [
      {
        label: "Mark as paid",
        onClick: () => updatePayrollStatus(selectedPayrolls, "PAID"),
      },
      {
        label: "Export payrolls",
        onClick: () => {
          getExportedPayrolls({ variables: { payrollIds: selectedPayrolls } })
          notify(
            "success",
            "Exporting payrolls",
            "your download should begin shortly"
          )
        },
      },
    ],
    [selectedPayrolls, updatePayrollStatus, notify, getExportedPayrolls]
  )

  const getActionsList = useCallback(
    (status: Reseller.PayrollStatusType, index: number) => {
      if (status === PayrollStatus.PAID) {
        return [
          {
            label: "View payroll",
            onClick: () => {
              selectedPayrollIndex !== index
                ? setSelectedPayrollIndex(index)
                : null
              setPayrollModalOpen(true)
            },
          },
        ]
      } else {
        return [
          {
            label: "View payroll",
            onClick: () => {
              selectedPayrollIndex !== index
                ? setSelectedPayrollIndex(index)
                : null
              setPayrollModalOpen(true)
            },
          },
          {
            label: "Mark as paid",
            onClick: () =>
              updatePayrollStatus([data?.edges?.[index]?.node.id], "PAID"),
          },
        ]
      }
    },
    [selectedPayrollIndex, updatePayrollStatus, data]
  )

  const handleFilterChange = (index: number) => {
    refetch({
      status: payrollFilters[index].filterRef,
    })
    setSelectedFilterIndex(index)
  }

  const handleFetchMorePayrolls = useCallback(() => {
    fetchMore({
      variables: {
        after: data?.pageInfo.endCursor,
      },
    })
  }, [fetchMore, data?.pageInfo.endCursor])

  const toggleSelectAll = useCallback(
    (e: any) => {
      if (e.target.checked) {
        setSelectAll(true)
        setSelectedPayrolls(() => {
          return payrolls.map((payroll) => {
            return payroll.id
          })
        })
      } else {
        setSelectedPayrolls([])
        setSelectAll(false)
      }
    },
    [payrolls]
  )

  const updateSelectedList = useCallback(
    (e: any) => {
      if (e.target.checked) {
        setSelectedPayrolls((selectedPayrolls) => [
          ...selectedPayrolls,
          e.target.value,
        ])
      } else {
        const targetId = selectedPayrolls.indexOf(e.target.value)
        if (targetId !== -1) {
          setSelectedPayrolls((selectedPayrolls) => {
            selectedPayrolls.splice(targetId, 1)
            return [...selectedPayrolls]
          })
          setSelectAll(false)
        }
      }
    },
    [selectedPayrolls]
  )

  const tableHeadings: TableHeading[] = useMemo(
    () => [
      {
        name: (
          <Checkbox
            id="selectAll"
            name={`checkbox-1`}
            toggleCheckCallback={toggleSelectAll}
            isChecked={selectAll}
          />
        ),
        columnAllowance: "min-content",
        showOnMobile: true,
        accessor: "id",
        renderData: (index: number, id: string) => {
          return (
            <BulkActionCheckbox
              key={`bulk-action-${index}`}
              id={id}
              name={`checkbox${index}`}
              toggleCheckCallback={updateSelectedList}
              isChecked={selectAll ? true : selectedPayrolls.includes(id)}
            />
          )
        },
      },
      {
        name: "Associates",
        columnAllowance: "2fr",
        showOnMobile: true,
        accessor: "associateDetails",
        renderData: (
          index: number,
          details: {
            name: string
            email: string
          }
        ) => {
          return (
            <AssociateContainer key={`associate-${details.name}`}>
              <p className="associate-email">{details.email}</p>
              <p className="associate-name">{details.name}</p>
            </AssociateContainer>
          )
        },
      },
      {
        name: "Status",
        columnAllowance: "1fr",
        accessor: "status",
        renderData: (index: number, status: string) =>
          renderChip(status, index),
      },
      {
        name: "Created On",
        columnAllowance: "1fr",
        accessor: "createdAt",
        renderData: (index: number, createdAt: string) => (
          <RowTextField key={`createdAt-${index}`} gridArea={"invoiceDate"}>
            {formatDateToString(createdAt)}
          </RowTextField>
        ),
      },
      {
        name: "Amount Owed",
        columnAllowance: "1fr",
        accessor: "totalAssociateCut",
        renderData: (index: number, totalAssociateCut: number) => (
          <RowTextField key={`totalAmount-${index}`} gridArea={"amountOwed"}>
            R{totalAssociateCut}
          </RowTextField>
        ),
      },
      {
        name:
          selectedPayrolls.length !== 0 ? (
            <ActionDropdown
              key="action-dropdown-header"
              actions={bulkActionsList}
              loading={exportPayrollsLoading}
              isBulkAction
            />
          ) : (
            <>Actions</>
          ),
        columnAllowance: "1fr",
        showOnMobile: true,
        accessor: "status",
        renderData: (index: number, status: string) => {
          return (
            <div key={`actions-${index}`} style={{ gridArea: "action" }}>
              <ActionDropdown
                actions={getActionsList(
                  status as Reseller.PayrollStatusType,
                  index
                )}
              />
            </div>
          )
        },
      },
    ],
    [
      getActionsList,
      toggleSelectAll,
      updateSelectedList,
      selectAll,
      selectedPayrolls,
      bulkActionsList,
      exportPayrollsLoading,
    ]
  )

  return (
    <PageGuard verifyUserProfile allowedProfileTypes={["PRINCIPAL"]}>
      <Seo title="Payrolls" />
      <PageHeader cypressTestId="payroll-page-header" title="Payrolls" />
      <PageNotificationBanner>
        {user?.profile?.verificationStatus !== "SUCCESSFUL" ? (
          <p>
            Your identity has not been verified yet. Please{" "}
            <Link className="highlighted" to="/onfido/">
              verify your identity
            </Link>{" "}
            to list your payrolls.
          </p>
        ) : (
          <p>View and manage your payrolls</p>
        )}
      </PageNotificationBanner>

      {user?.profile?.verificationStatus === "SUCCESSFUL" ? (
        <>
          <Filter
            isPageFilter
            filterOptions={payrollFilters}
            onClick={handleFilterChange}
            activeIndex={selectedFilterIndex}
          />
          <Table
            headings={tableHeadings}
            rowData={payrolls}
            loading={loading}
            emptyTableText="You have no payrolls to view"
            allRowsLoaded={!data?.pageInfo.hasNextPage}
            handleLoadMoreRows={handleFetchMorePayrolls}
          />
          {!loading && payrolls?.length > 0 && payrollModalOpen ? (
            <PayrollModal
              open={payrollModalOpen}
              onClose={() => setPayrollModalOpen(false)}
              payroll={data?.edges[selectedPayrollIndex]?.node}
            />
          ) : null}
        </>
      ) : null}
    </PageGuard>
  )
}

export default PrincipalPayroll
